@use "../global" as *;

.banner-section {
  width: 100%;
  height: auto;
  display: flex;
  background-color: #2d2d2d;
  margin: 8rem 0;
  padding: 6rem 0;
  text-align: center;
}

.banner-content {
  color: white;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-items: center;

  &__text {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    h2 {
      font-size: $h1-size;
      line-height: 1.3;

      @media (max-width: 550px) {
        font-size: $h2-size;
      }
    }

    p {
      font-size: $h3-size;

      @media (max-width: 550px) {
        font-size: 2rem;
      }
    }

    span {
      color: #5ce1e6;
      font-weight: 500;
    }
  }
}
