.instagram-feed {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  padding: 16px;
}

.instagram-photo {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.instagram-photo img {
  width: 100%;
  height: auto;
  display: block;
}

.photo-date {
  position: absolute;
  bottom: 8px;
  left: 8px;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
}

.instagram-photo:hover {
  transform: scale(1.05);
}

.error-message {
  color: red;
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.button {
  align-items: center;

  display: flex;
  align-items: center;
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #5271ff ;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

.button img {
  align-items: center;

  margin-right: 8px;
}

.button:hover {
  background-color: #5ce1e6;
  color: #000;
}

.button a {
  color: #fff;
  text-decoration: none;
}

.button a:hover {
  text-decoration: underline;
  color: #000;
}
